<template>
  <div class="container">
    <div>
      <button @click="returnToMainPage()" class="return-mainPage">
        Powrót
      </button>
    </div>
    <div class="button-container">
        <button @click="addNewWindow()">
          Dodaj nowe okno
        </button>
    </div>
    <div>
      <button @click="submit()" class="submit">
        Potwierdzam i wysyłam poniższe zestawienie na maila
      </button>
    </div>
    <div v-if="emailSend" class="email-send-message">
      <p>Na maila została wysłana konfiguracja okien</p>
    </div>
    <div class="email-container">
      <p>Zestawienie wyślij również do: &#40;adres e-mail&#41;</p>
      <input v-model="yourEmail" type="text" />
    </div>
    <table cellspacing="0" cellpadding="0">
      <tr>
        <th>L.p.</th>
        <th>System</th>
        <th>Szer. x wys.</th>
        <th>Ilość</th>
        <th>Kolor</th>
        <th>Ilość szyb</th>
        <th>Typ</th>
        <th>Frezowanie</th>
        <th>Klamki</th>
        <th>Otwieranie</th>
        <th>Słupek</th>
        <th>Próg</th>
        <th>Kolor wewnętrzny</th>
        <th>Kolor zewnętrzny</th>
        <th>Wypełnienie</th>
        <th>Hamulec</th>
        <th>Nawiewnik okapnik</th>
        <th>Haczyki</th>
        <th>Forviders</th>
        <th>Szprosy</th>
        <th>Typ szprosów</th>
        <th>Kolor szprosów</th>
        <th>Il. szprosów poziomo/pionowo</th>
        <th>Okucia</th>
        <th>Zasuwnica</th>
        <th>Pochwycik balkonowy z zatrzaskiem</th>
        <th>Wkładka</th>
        <th>Opis</th>
        <th>Klient</th>
        <th>Termin</th>
        <th>Opis zamówienia</th>
      </tr>
      <tr
        v-for="window in windows"
        :key="window"
        class="windowTable"
        id="windowTable"
      >
      <td>
        <div class="insideTD">{{ windows.indexOf(window) + 1 }}</div>
          <button class="editButton"
            @click="editWindow(window.objectId)"
          > Edytuj</button>
        </td>
        <td class="long-column">
          <div class= "insideTD">{{ window.windowSystem }}</div>
        </td>
        <td>
          <div class="insideTD">{{window.winWidth + " x " + window.winHeight }}</div>
        </td>
         <td>
          <div class="insideTD">{{ window.winQuantity }}</div>
        </td>
         <td>
          <div class="insideTD">{{ window.winProfile }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winGlassesNumber }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.windowType }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winMilling }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winHandles }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winOpen }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winPost }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winDoorStep }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winInsideColor }} {{ window.winInsideColorRAL }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winOutsideColor }} {{ window.winOutsideColorRAL }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winFilling }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winBrake }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winDiffuserDripCap }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winHooks }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winForviders }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winMuntins }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winMuntinType }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winMuntinColor }} {{ window.winMuntinColorRAL }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winCuantityHorizontalMuntins }} {{ window.winCuantityVerticalMuntins }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winFitting }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winEspagnolette }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winGrabber }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winInsert }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.winDescription }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.client }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.dates }}</div>
        </td>
        <td>
          <div class="insideTD">{{ window.orderDescription }}</div>
        </td>
      </tr>
    </table>

    <EditableWindow v-if="editableWindowIsVisible" :winEdit = "this.windowToEdit"
    @updateWindow= "updateEditableWIndow"
    @closeEditableWindow = "closeWindow"
    @deleteTable = "deleteThisTable"
    />

    <div v-if="changeValueIsVisible" class="change-value">
      <div class="background"></div>
      <div class="new-value-box">
        <div>
          <p>Nowa wartość:</p>
          <input :type="inputType" v-model="newValue" />
        </div>
        <div class="button-container">
          <button @click="cancel()">Anuluj</button>
          <button @click="saveNewValue()">Zapisz</button>
        </div>
        <div v-if="errorMessageIsVisible" class="error-container">
          <p class="error-message">
            Coś poszło nie tak. Zmiany nie zostały zapisane.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import emailjs from "@emailjs/browser";
import EditableWindow from "./EditableWindow.vue"
export default {
  data() {
    return {
      windows: [],
      yourEmail: "",
      newValue: "",
      changeValueIsVisible: false,
      changedObjectId: "",
      changedObjectAttribute: "",
      inputType: "text",
      emailSend: false,
      errorMessageIsVisible: false,

      editableWindowIsVisible: false,
      windowToEdit: []
    };
  },
    components: {
    EditableWindow
  },
  mounted() {
    axios
      .get(`https://sizablepot.backendless.app/api/data/windows`)
      .then((response) => (this.windows = response.data));
  },
  methods: {
    fetchData() {
      axios
        .get(`https://sizablepot.backendless.app/api/data/windows`)
        .then((response) => (this.windows = response.data));
    },
    addNewWindow() {
      this.fetchData();
      this.clearWindowsStore();
      this.$router.push("/clientData");
    },
    updateWindowToEdit(id) {
      let updatedWindow = this.windowsList.find((window)=>window.objectId == id);
        this.winToEdit = updatedWindow;
    },
    async updateEditableWIndow(id) {

      const response = await axios.get(`https://sizablepot.backendless.app/api/data/windows`);
      this.windows = response.data;
      let updatedWindow = this.windows.find((window)=>window.objectId == id);
       this.windowToEdit = updatedWindow;
    },
    returnToMainPage() {
      this.$router.push("/");
    },
    editWindow(id) {
      this.editableWindowIsVisible = true;
      let winToEdit = this.windows.find((window)=>window.objectId == id);
      this.windowToEdit = winToEdit;
    },
    deleteThisTable(id) {
      let text = "Czy na pewno chcesz usunąć konfigurację okna?";
      if (confirm(text) == true) {
        axios
          .delete(`https://sizablepot.backendless.app/api/data/windows/${id}`)
          .then((response) => {
            console.log(response);
            console.log(`Deleted window with ID ${id}`);
            this.fetchData();
            this.editableWindowIsVisible = false;
          })
          .catch((error) => {
            this.errorMessageIsVisible = true;
            setTimeout(() => {
              this.errorMessageIsVisible = false;
            }, "5000");
            console.log(error);
          });
      } else {
        return;
      }
    },
    closeWindow() {
      this.fetchData();
      this.editableWindowIsVisible = false;
    },
    submit() {
      this.fetchData();
      // let mail = this.yourEmail;
      var windowsToSend = [];
      //gets table
      var winRows = document.getElementsByClassName("windowTable");
      //gets rows of table
      var attrNames = document.getElementsByTagName("th");
      for (let i = 0; i < winRows.length; i++) {
        var rowLength = winRows[i].cells.length;
        console.log(rowLength);
        //loops through cells
        for (var j = 1; j < rowLength - 1; j++) {
          //gets cells of header
          var attrName = attrNames[j].innerText;
          var oCells = winRows[i].cells[j].firstChild.innerHTML;
          if(oCells !=="") {
            var keyValue = "<br>" + attrName + ": " + oCells;
            windowsToSend.push(keyValue);
          }
      }
      windowsToSend.push("<br><br>");
      }

      const templateParams = {
        Okna: windowsToSend,
        yourEmail: this.yourEmail,
      };
      emailjs
        .send(
          "service_u5bo6u3",
          "template_7qaf81v",
          templateParams,
          "Bk9OW_oJI8NKfrxhJ"
        )
        .then(
          (response) => {
            this.emailSend = true;
            console.log("SUCCESS!", response.status, response.text);
          },
          (err) => {
            this.errSend = true;
            console.log("FAILED...", err);
          }
        );

      setTimeout(() => {
        console.log("I'm here");
        this.emailSend = false;
        this.clearStore();
        //   this.$router.push('/');
      }, 5000);
    },
        clearWindowsStore() {
        this.$store.commit("setAreHandles", true);
        this.$store.commit("setIsPost", false);
        this.$store.commit("setPosts", []);
        this.$store.commit("setColorsInsideOrOutside", []);
        this.$store.commit("setIsDoorstep", false);
        this.$store.commit("setDoorSteps", []);
        this.$store.commit("setIsOpened", false);
        this.$store.commit("setOpenedOptions", []);
        this.$store.commit("setFilling", []);
        this.$store.commit("setIsDiffuser", true);
        this.$store.commit("setAreHooksForviders", true);
        this.$store.commit("setAreMuntins", true);
        this.$store.commit("setIsBrake", false);
        this.$store.commit("setIsFitting", false);
        this.$store.commit("setIsGrabber", false);
        this.$store.commit("setIsEspagnolette", false);
        this.$store.commit("setIsInsert", false);
        this.$store.commit("setWinQuantity", null);
        this.$store.commit("setSystem", "");
        this.$store.commit("setChoosenSystem", []);
        this.$store.commit("setType", "");
        this.$store.commit("setWinWidth", null);
        this.$store.commit("setWinHeight", null);
        this.$store.commit("setWinProfile", "");
        this.$store.commit("setProfilesToChoose", []);
        this.$store.commit("setWinMilling", "");
        this.$store.commit("setWinHandles", "");
        this.$store.commit("setWinOpen", "");
        this.$store.commit("setWinPost", "");
        this.$store.commit("setDoorStep", null);
        this.$store.commit("setWinInsideColor", "");
        this.$store.commit("setWinInsideColorRAL", "");
        this.$store.commit("setWinOutsideColor", "");
        this.$store.commit("setWinOutsideColorRAL", "");
        this.$store.commit("setWinFilling", "");
        this.$store.commit("setGlassesNumber", "");
        this.$store.commit("setWinBrake", "");
        this.$store.commit("setWinHooks", "");
        this.$store.commit("setWinForviders", "");
        this.$store.commit("setWinMuntins", "");
        this.$store.commit("setWinMuntinType", "");
        this.$store.commit("setWinMuntinColor", "");
        this.$store.commit("setWinMuntinColorRAL", "");
        this.$store.commit("setWinCuantityHorizontalMuntins", "");
        this.$store.commit("setCuantityVerticalMuntins", "");
        this.$store.commit("setWinEspagnolette", "");
        this.$store.commit("setWinGrabber", "");
        this.$store.commit("setWinInsert", "");
        this.$store.commit("setWinDescription", "");
        this.$store.commit("setWinFitting", "");
        this.$store.commit("resetWindowsStore");
      this.yourEmail = "";
      console.log(this.$store.state.winPost);
      console.log(this.$store.state.winPost);
      console.log("Windows in store");
      console.log(this.$store.state.Windows);
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.email-container {
  margin: 20px;
}
.email-send-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  margin: 20px;
  padding: 15px;
  background-color: #4caf50;
}
.add-new-window {
  margin: 20px 20px 0 20px;
  padding: 15px;
}
table {
  margin: 20px;
  border: none;
}
th {
  padding: 5px;
  height: 50px;
  font-size: 16px;
}
td {
  min-width: 100px;
  min-height: 50px;
  padding: 0;
  font-size: 15px;
  text-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
table,
th,
td {
  position: relative;

}
tr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0;
}
th:nth-child(1), th:nth-child(4), th:nth-child(6), th:nth-child(8), th:nth-child(16), th:nth-child(18), th:nth-child(19), th:nth-child(20) {
  min-width: 100px;
}
td:nth-child(1), td:nth-child(4), td:nth-child(6), td:nth-child(8), td:nth-child(16), td:nth-child(18), td:nth-child(19), td:nth-child(20) {
  min-width: 100px;
  max-width: 100px;
}
th:nth-child(3), th:nth-child(5), th:nth-child(7), th:nth-child(9), th:nth-child(10), th:nth-child(11), th:nth-child(15), th:nth-child(17), th:nth-child(21), th:nth-child(22), th:nth-child(25), th:nth-child(26), th:nth-child(27), th:nth-child(30) {
  min-width: 150px;
  max-width: 150px;
}
td:nth-child(3), td:nth-child(5), td:nth-child(7), td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(15), td:nth-child(17), td:nth-child(21), td:nth-child(22), td:nth-child(25), td:nth-child(26), td:nth-child(27), td:nth-child(30) {
  min-width: 150px;
  max-width: 150px;
}
th:nth-child(2), th:nth-child(12), th:nth-child(13), th:nth-child(14), th:nth-child(23), th:nth-child(24), th:nth-child(28), th:nth-child(31) {
  min-width:250px;
  max-width: 250px;
}
td:nth-child(2), td:nth-child(12), td:nth-child(13), td:nth-child(14), td:nth-child(23), td:nth-child(24), td:nth-child(28), td:nth-child(31)  {
  min-width: 250px;
  max-width: 250px;
}
th:nth-child(29) {
  min-width: 350px;
  max-width: 350px;
}
td:nth-child(29) {
  min-width: 350px;
  max-width: 350px;
}
.insideTD {
  padding: 5px;
  margin: 0px;
}
button {
  margin: 0;
  width: 100%;
}
.editButton {
  height: 35px;
  width: 60px;
  border: 1px solid black;
  margin: 5px;
  padding: 10px 0px;
}
.submit {
  margin: 20px 20px 0 20px;
  height: 50px;
}
.button-container button {
  margin: 20px 20px 0 20px;
  height: 50px;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
input {
  font-size: 19px;
  height: 30px;
  padding: 5px;
  width: 100%;
}

.return-mainPage {
  background-color: #006600;
  margin: 20px 20px 0 20px;
  height: 50px;
  font-weight: 600;
  color: white;
}
.delete-table-button {
  background-color: #990000;
  color: white;
}
@media only screen and (min-width: 0px) and (max-width: 1200px) {
  body {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .container {
    width: 100%;
    font-size: 17px;
    margin: 0;
    padding: 0;
  }
  table {
    margin: 20px 5px;
    width: 100%;
  }
  th {
    height: 40px;
    font-size: 16px;
  }
  .short-column {
    width: 150px;
    padding: 0;
    border: 1px solid red;
  }
.long-column {
  text-wrap: wrap;
}
  td {
    padding: 5px;
    font-size: 15px;
  }
  .insideTD {
    padding: 3px;
  }
  .submit {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 100%;
  }
  .button-container button {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 100%;
}
  .return-mainPage {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 100%;
  }
  .add-new-window {
    margin: 5px 5px 0 5px;
    padding: 15px;
    width: 100%;
  }
  .email-send-message {
    width: 100%;
    font-size: 17px;
    margin: 20px 0;
    padding: 10px;
    background-color: #4caf50;
  }
  .new-value-box {
    position: fixed;
    top: 20vh;
    left: 10%;
    width: 80%;
  }
  .new-value-box div {
    margin: 15px;
    width: 100%;
  }
  input {
    font-size: 17px;
    height: 30px;
    padding: 5px;
    width: 100%;
    margin: 0;
  }
  button {
    padding: 3px 15px;
    font-size: 16px;
  }
}
</style>
