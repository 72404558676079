<template>
  <div class="container">
    <img :src="imgSrc" alt="Nordic Wooden House" />
    <div class="inside-container">
      <div v-if="errorMessageIsVisible">
        <p class="error-message">
          Coś poszło nie tak. Sprawdź połączenie z internetem i spróbuj jeszcze
          raz.
        </p>
      </div>
      <div class="button-container">
        <button @click="newOrder()">
          Nowe zamówienie
          <p>Ta opcja usunie wcześniejsze konfiguracje okien.</p>
        </button>
      </div>
      <div class="button-container">
        <button @click="showSavedWindows()">Przejrzyj okna w bazie</button>
      </div>
      <div class="button-container">
        <button @click="addNewWindow()">
          Dodaj nowe okno do wcześniejszego zamówienia
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import logo from "../../docs/images/NWH/NWH.png";
export default {
  data() {
    return {
      errorMessageIsVisible: false,
      imgSrc: logo,
      windows: [],
    };
  },
  mounted() {
    axios
      .get(`https://sizablepot.backendless.app/api/data/windows`)
      .then((response) => (this.windows = response.data));
  },
  methods: {
    fetchData() {
      axios
        .get(`https://sizablepot.backendless.app/api/data/windows`)
        .then((response) => (this.windows = response.data));
    },
    newOrder() {
      let text = "Czy na pewno chcesz rozpocząć nowe zamówienie?";
      if (confirm(text) == true) {
        this.fetchData();
        var windowsId = [];
        var windows = this.windows;
        for (var i = 0; i < windows.length; i++) {
          let windowId = windows[i].objectId;
          windowsId.push(windowId);
        }
        if (windowsId.length > 0) {
          for (var j = 0; j < windowsId.length; j++) {
            axios
              .delete(
                `https://sizablepot.backendless.app/api/data/windows/${windowsId[j]}`
              )
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                this.errorMessageIsVisible = true;
                setTimeout(() => {
                  this.errorMessageIsVisible = false;
                }, "5000");
                console.log(error);
              });
          }
        }
      } else {
        return;
      }
      this.clearWindowsStore();
      this.clearClientStore();

    },
    showSavedWindows() {
      this.$router.push("/windowsList");
    },
    addNewWindow() {
      this.fetchData();
      this.clearWindowsStore();
      this.$router.push("/clientData");
    },
    clearWindowsStore() {
      (this.$store.state.winQuantity = ""),
        (this.$store.state.windowSystem = ""),
        (this.$store.state.windowType = ""),
        (this.$store.state.winWidth = ""),
        (this.$store.state.winHeight = ""),
        (this.$store.state.winProfile = ""),
        (this.$store.state.winMilling = ""),
        (this.$store.state.winHandles = ""),
        (this.$store.state.winOpen = ""),
        (this.$store.state.winPost = ""),
        (this.$store.state.winDoorStep = ""),
        (this.$store.state.winInsideColor = ""),
        (this.$store.state.winInsideColorRAL = ""),
        (this.$store.state.winOutsideColor = ""),
        (this.$store.state.winOutsideColorRAL = ""),
        (this.$store.state.winFilling = ""),
        (this.$store.state.winGlassesNumber = ""),
        (this.$store.state.winBrake = ""),
        (this.$store.state.winDiffuserDripCap = ""),
        (this.$store.state.winHooks = "");
      this.$store.state.winForviders = "";
      this.$store.state.winMuntins = "";
      this.$store.state.winMuntinType = "";
      this.$store.state.winMuntinColor = "";
      this.$store.state.winMuntinColorRAL = "";
      this.$store.state.winCuantityHorizontalMuntins = "";
      this.$store.state.winCuantityVerticalMuntins = "";
      this.$store.state.winFitting = "";
      this.$store.state.winEspagnolette = "";
      this.$store.state.winGrabber = "";
      this.$store.state.winDescription = "";
      this.$store.state.Windows = [];
      this.yourEmail = "";
    },
    clearClientStore() {
      (this.$store.state.client = []),
        (this.$store.state.dateFrom = "BRAK"),
        (this.$store.state.dateTo = "BRAK"),
        (this.$store.state.description = "");
    },
  },
};
</script>
<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inside-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button-container {
  margin: 10px;
  width: 100%;
}
button {
  margin: 10px;
  width: 100%;
  padding: 15px 20px;
  font-size: 20px;
}
button p {
  font-size: 16px;
  color: #990000;
}
.error-message {
  display: inline-block;
  margin: 20px auto;
  color: red;
  font-size: bolder;
  font-size: 21px;
}
img {
  width: 200px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 0px) and (max-width: 1200px) {
  .container {
    margin:0;
    padding:0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .inside-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button-container {
    margin: 0;
    width: 90%;
  }
  button {
    margin: 10px;
    width: 100%;
    padding: 10px 15px;
    font-size: 18px;
  }
  button p {
    font-size: 15px;
    color: #990000;
  }
  .error-message {
    display: inline-block;
    margin: 20px auto;
    color: red;
    font-size: bolder;
    font-size: 18px;
  }
}
</style>
