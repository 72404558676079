<template>
  <div>
  <div class="background"></div>
    <div class="editable-window">
    <div class="tableBox">
    <table>
      <tr>
        <th>Atrybut</th>
        <th>wartość</th>
        <th></th>
      </tr>
      <tr>
        <td>Klient</td>
        <td>{{ winToEdit.client }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'client', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Daty</td>
        <td>{{ winToEdit.dates }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'dates', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.orderDescription">
        <td>Opis zamówienia</td>
        <td>{{ winToEdit.orderDescription }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'orderDescription', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>System</td>
        <td>{{ winToEdit.windowSystem }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'windowSystem', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Typ</td>
        <td>{{ winToEdit.windowType }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'windowType', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Szerokość</td>
        <td>{{ winToEdit.winWidth }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winWidth', 'number')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Wysokość</td>
        <td>{{ winToEdit.winHeight }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winHeight', 'number')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Ilość</td>
        <td>{{ winToEdit.winQuantity }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winQuantity', 'number')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Frezowanie</td>
        <td>{{ winToEdit.winMilling }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winMilling', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winHandles">
        <td>Klamki</td>
        <td>{{ winToEdit.winHandles }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winHandles', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winOpen">
        <td>Otwieranie</td>
        <td>{{ winToEdit.winOpen }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'winOpen', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winPost">
        <td>Słupek</td>
        <td>{{ winToEdit.winPost }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'winPost', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winDoorStep">
        <td>Próg</td>
        <td>{{ winToEdit.winDoorStep }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winDoorStep', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Profil</td>
        <td>{{ winToEdit.winProfile }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winProfile', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Kolor wewnętrzny</td>
        <td>
          {{ winToEdit.winInsideColor }} {{ winToEdit.winInsideColorRAL }}
        </td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winInsideColor', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Kolor zewnętrzny</td>
        <td>
          {{ winToEdit.winOutsideColor }} {{ winToEdit.winOutsideColorRAL }}
        </td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winOutsideColor', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Wypełnienie</td>
        <td>{{ winToEdit.winFilling }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winFilling', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Ilość szyb</td>
        <td>{{ winToEdit.winGlassesNumber }}</td>
        <td>
          <button
            @click="
              changeValue(winToEdit.objectId, 'winGlassesNumber', 'number')
            "
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winBrake">
        <td>Hamulec</td>
        <td>{{ winToEdit.winBrake }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'winBrake', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winDiffuserDripCap">
        <td>Nawiewnik i okapnik</td>
        <td>{{ winToEdit.winDiffuserDripCap }}</td>
        <td>
          <button
            @click="
              changeValue(winToEdit.objectId, 'winDiffuserDripCap', 'text')
            "
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winHooks">
        <td>Haczyki</td>
        <td>{{ winToEdit.winHooks }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'winHooks', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winForviders">
        <td>Forviders</td>
        <td>{{ winToEdit.winForviders }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winForviders', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winMuntins">
        <td>Szprosy</td>
        <td>{{ winToEdit.winMuntins }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winMuntins', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winMuntinType">
        <td>Typ szprosów</td>
        <td>{{ winToEdit.winMuntinType }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winMuntinType', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winMuntinColor">
        <td>Kolor szprosów:</td>
        <td>
          {{ winToEdit.winMuntinColor }} {{ winToEdit.winMuntinColorRAL }}
        </td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winMuntinColor', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winCuantityHorizontalMuntins">
        <td>Ilość szprosów poziomo</td>
        <td>{{ winToEdit.winCuantityHorizontalMuntins }}</td>
        <td>
          <button
            @click="
              changeValue(
                winToEdit.objectId,
                'winCuantityHorizontalMuntins',
                'number'
              )
            "
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winCuantityVerticalMuntins">
        <td>Ilość szprosów pionowo</td>
        <td>{{ winToEdit.winCuantityVerticalMuntins }}</td>
        <td>
          <button
            @click="
              changeValue(
                winToEdit.objectId,
                'winCuantityVerticalMuntins',
                'number'
              )
            "
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winFitting">
        <td>Okucia</td>
        <td>{{ winToEdit.winFitting }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winFitting', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winEspagnolette">
        <td>Zasuwnica</td>
        <td>{{ winToEdit.winEspagnolette }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winEspagnolette', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winGrabber">
        <td>Pochwycik balkonowy z zatrzaskiem</td>
        <td>{{ winToEdit.winGrabber }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winGrabber', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winInsert">
        <td>Wkładka</td>
        <td>{{ winToEdit.winInsert }}</td>
        <td>
          <button @click="changeValue(winToEdit.objectId, 'winInsert', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="winToEdit.winDescription">
        <td>Opis</td>
        <td>{{ winToEdit.winDescription }}</td>
        <td>
          <button
            @click="changeValue(winToEdit.objectId, 'winDescription', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <th colspan="3">
          <button
            class="ok-table-button"
            @click="returnToWindows()"
          >
            OK
          </button>
        </th>
      </tr>
      <tr>
        <th colspan="3">
          <button
            class="delete-table-button"
            @click="deleteTable(winToEdit.objectId)"
          >
            Usuń tą konfigurację okien
          </button>
        </th>
      </tr>
    </table>
    </div>
    <div v-if="changeValueIsVisible" class="change-value">
      <div class="background"></div>
      <div class="new-value-box">
        <div>
          <p>Nowa wartość:</p>
          <input :type="inputType" v-model="newValue" />
        </div>
        <div class="button-container">
          <button @click="cancel()">Anuluj</button>
          <button @click="saveNewValue()">Zapisz</button>
        </div>
        <div v-if="errorMessageIsVisible" class="error-container">
          <p class="error-message">
            Coś poszło nie tak. Zmiany nie zostały zapisane.
          </p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["winEdit"],
  data() {
    return {
        winToEdit: [],
        windowsList: [],
        changeValueIsVisible: '',
        changedObjectId: '',
        changedObjectAttribute: '',
        inputType: '',
        newValue: '',
        errorMessageIsVisible: ''
    };
  },
  mounted() {
        this.winToEdit = this.winEdit;
        console.log(this.winToEdit);
  },
  watch: {
    winEdit() {
      this.winToEdit = this.winEdit;
      console.log(this.winToEdit);
    }
  },
  methods: {
    fetchData() {
      axios
        .get(`https://sizablepot.backendless.app/api/data/windows`)
        .then((response) => (this.windowsList = response.data));
    },
     updateEditableWindow(id) {
      let updatedWindow = this.windowsList.find((window)=>window.objectId == id);
      this.winToEdit = updatedWindow;
      console.log(this.winToEdit);
      console.log(id);
    },
    changeValue(id, attr, inputType) {
      this.changeValueIsVisible = true;
      this.changedObjectId = id;
      this.changedObjectAttribute = attr;
      this.inputType = inputType;
      this.newValue = "";
    },
    deleteTable(id) {
      this.$emit("deleteTable", id);
    },
    cancel() {
      this.changeValueIsVisible = false;
      this.changeObjectId = "";
      this.changedObjectAttribute = "";
      this.inputType = "text";
    },
    saveNewValue() {
      let id = this.changedObjectId;
      let attrToChange = this.changedObjectAttribute;
      let value = this.newValue;
      parseInt(value);
      let valueToChange;
      valueToChange = { [attrToChange]: value };

      axios
        .put(
          `https://sizablepot.backendless.app/api/data/windows/${id}`,
          valueToChange
        )
        .then((response) => {
          if (response.status === 200) {
            this.$emit("updateWindow", id);
          }
        })
        .catch((error) => {
          this.errorMessageIsVisible = true;
          setTimeout(() => {
            this.errorMessageIsVisible = false;
          }, "7000");
          console.log(error);
        });
      this.changeValueIsVisible = false;
    },
    returnToWindows() {
      this.$emit("closeEditableWindow");
    }
  }
};
</script>

<style scoped>
.tableBox {
    width: 100%;
    height: 100%;
}
table {
  margin: 20px auto;
  width: 70%;
  height: 100%;
}
th {
  height: 50px;
  font-size: 20px;
}
td {
  padding: 5px;
  height: 20px;
  font-size: 18px;
}
td:nth-child(2) {
  width: 70%;
}
table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
}
button {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 3px 20px;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editable-window {
  position: fixed;
  top: 5vh;
  left: 15%;
  width: 70%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  margin: 0;
  overflow: scroll;
}
.new-value-box {
  position: fixed;
  top: 20vh;
  left: 30%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  margin: 0;
  overflow: hidden;
}
.new-value-box div {
  margin: 15px;
  width: 100%;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.button-container button {
  margin: 10px;
  padding: 10px 20px;
}
.error-message {
  display: inline-block;
  margin: 20px auto;
  color: red;
  font-size: bolder;
  font-size: 21px;
}
.delete-table-button {
  background-color: #EF5350;
}
.delete-table-button:hover {
  background-color: #D32F2F;
}
@media only screen and (min-width: 0px) and (max-width: 1200px) {
.editable-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.tableBox {
  width: 100%;
  height: 100%;
  margin: 0;
  padding:0;
  overflow: scroll;
}
table {
  margin:0 auto;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
th {
  padding: 3px;
  height: 50px;
  font-size: 16px;
}
td {
  padding: 3px;
  height: 20px;
  font-size: 15px;
}
td:nth-child(2) {
  width: 65%;
}
table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
}
button {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 3px 20px;
}
td button {
  padding: 3px 5px;
  font-size: 13px;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
}
.new-value-box {
  z-index: 1000;
  position: fixed;
  top: 5vh;
  left: 10vw;
  width: 80vw;
  padding: 10px;
  margin: 0;
  overflow: hidden;
}
.new-value-box div {
  margin: 15px;
  width: 100%;
}
.new-value-box input {
  height: 30px;
  width: 100%;
  padding: 2px;
  font-size: 17px;
}

}
</style>
