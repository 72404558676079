import { createRouter, createWebHistory } from 'vue-router';
//import VueRouter from 'vue-router'

import TheBeginning from './components/TheBeginning.vue';
import ClientData from './components/ClientData.vue';
import NewOffer from './components/NewOffer.vue';
import OfferList from './components/OfferList.vue';
import NewWindow from './components/NewWindow.vue';
import MainInfo from './components/MainInfo.vue';
import AllWindows from './components/AllWindows.vue';
import ServerWindows from './components/ServerWindows.vue';
import WindowsList from './components/WindowsList.vue';


export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'TheBeginning', component: TheBeginning},
        {path: '/clientData', name: 'ClientData', component: ClientData},
        {path: '/newOffer', name: 'NewOffer', component: NewOffer},
        {path: '/offerList', name: 'OfferList', component: OfferList},
        {path: '/newWindow', name: 'NewWindow', component: NewWindow},
        {path: '/mainInfo', name: 'MainInfo', component: MainInfo},
        {path: '/serverWindows', name: 'ServerWindows', component: ServerWindows},
        {path: '/windowsList', name: 'WindowsList', component: WindowsList},
        {path: '/allWindows', name: 'AllWindows', component: AllWindows}
    ]
});

//export default {router};