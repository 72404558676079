<template>
  <div class="container">
    <div>
      <button class="add-new-window" @click="addNewWindow()">
        Dodaj nowe okno
      </button>
    </div>
    <div>
      <button @click="submit()" class="submit">
        Potwierdzam i wysyłam poniższe zestawienie na maila
      </button>
    </div>
    <div v-if="emailSend" class="email-send-message">
      <p>Na maila została wysłana konfiguracja okien</p>
    </div>
    <div class="email-container">
      <p>Zestawienie wyślij również do: &#40;adres e-mail&#41;</p>
      <input v-model="yourEmail" type="text" />
    </div>
    <table
      v-for="window in windows"
      :key="window"
      class="windowTable"
      id="windowTable"
    >
      <tr>
        <th>Atrybut</th>
        <th>wartość</th>
        <th></th>
      </tr>
      <tr>
        <td>Klient</td>
        <td>{{ window.client }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'client', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Daty</td>
        <td>{{ window.dates }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'dates', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.orderDescription">
        <td>Opis zamówienia</td>
        <td>{{ window.orderDescription }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'orderDescription', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>System</td>
        <td>{{ window.windowSystem }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'windowSystem', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Typ</td>
        <td>{{ window.windowType }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'windowType', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Szerokość</td>
        <td>{{ window.winWidth }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winWidth', 'number')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Wysokość</td>
        <td>{{ window.winHeight }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winHeight', 'number')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Ilość</td>
        <td>{{ window.winQuantity }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winQuantity', 'number')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Frezowanie</td>
        <td>{{ window.winMilling }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winMilling', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winHandles">
        <td>Klamki</td>
        <td>{{ window.winHandles }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winHandles', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winOpen">
        <td>Otwieranie</td>
        <td>{{ window.winOpen }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winOpen', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winPost">
        <td>Słupek</td>
        <td>{{ window.winPost }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winPost', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winDoorStep">
        <td>Próg</td>
        <td>{{ window.winDoorStep }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winDoorStep', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Profil</td>
        <td>{{ window.winProfile }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winProfile', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Kolor wewnętrzny</td>
        <td>{{ window.winInsideColor }} {{ window.winInsideColorRAL }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winInsideColor', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Kolor zewnętrzny</td>
        <td>{{ window.winOutsideColor }} {{ window.winOutsideColorRAL }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winOutsideColor', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Wypełnienie</td>
        <td>{{ window.winFilling }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winFilling', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <td>Ilość szyb</td>
        <td>{{ window.winGlassesNumber }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winGlassesNumber', 'number')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winBrake">
        <td>Hamulec</td>
        <td>{{ window.winBrake }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winBrake', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winDiffuserDripCap">
        <td>Nawiewnik i okapnik</td>
        <td>{{ window.winDiffuserDripCap }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winDiffuserDripCap', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winHooks">
        <td>Haczyki</td>
        <td>{{ window.winHooks }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winHooks', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winForviders">
        <td>Forviders</td>
        <td>{{ window.winForviders }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winForviders', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winMuntins">
        <td>Szprosy</td>
        <td>{{ window.winMuntins }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winMuntins', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winMuntinType">
        <td>Typ szprosów</td>
        <td>{{ window.winMuntinType }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winMuntinType', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winMuntinColor">
        <td>Kolor szprosów:</td>
        <td>{{ window.winMuntinColor }} {{ window.winMuntinColorRAL }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winMuntinColor', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winCuantityHorizontalMuntins">
        <td>Ilość szprosów poziomo</td>
        <td>{{ window.winCuantityHorizontalMuntins }}</td>
        <td>
          <button
            @click="
              changeValue(
                window.objectId,
                'winCuantityHorizontalMuntins',
                'number'
              )
            "
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winCuantityVerticalMuntins">
        <td>Ilość szprosów pionowo</td>
        <td>{{ window.winCuantityVerticalMuntins }}</td>
        <td>
          <button
            @click="
              changeValue(
                window.objectId,
                'winCuantityVerticalMuntins',
                'number'
              )
            "
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winFitting">
        <td>Okucia</td>
        <td>{{ window.winFitting }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winFitting', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winEspagnolette">
        <td>Zasuwnica</td>
        <td>{{ window.winEspagnolette }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winEspagnolette', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winGrabber">
        <td>Pochwycik balkonowy z zatrzaskiem</td>
        <td>{{ window.winGrabber }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winGrabber', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winInsert">
        <td>Wkładka</td>
        <td>{{ window.winInsert }}</td>
        <td>
          <button @click="changeValue(window.objectId, 'winInsert', 'text')">
            Zmień
          </button>
        </td>
      </tr>
      <tr v-if="window.winDescription">
        <td>Opis</td>
        <td>{{ window.winDescription }}</td>
        <td>
          <button
            @click="changeValue(window.objectId, 'winDescription', 'text')"
          >
            Zmień
          </button>
        </td>
      </tr>
      <tr>
        <th colspan="3">
          <button
            class="delete-table-button"
            @click="deleteTable(window.objectId)"
          >
            Usuń okno
          </button>
        </th>
      </tr>
    </table>
    <div v-if="changeValueIsVisible" class="change-value">
      <div class="background"></div>
      <div class="new-value-box">
        <div>
          <p>Nowa wartość:</p>
          <input :type="inputType" v-model="newValue" />
        </div>
        <div class="button-container">
          <button @click="cancel()">Anuluj</button>
          <button @click="saveNewValue()">Zapisz</button>
        </div>
        <div v-if="errorMessageIsVisible" class="error-container">
          <p class="error-message">
            Coś poszło nie tak. Zmiany nie zostały zapisane.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import emailjs from "@emailjs/browser";
export default {
  data() {
    return {
      windows: [],
      yourEmail: "",
      newValue: "",
      changeValueIsVisible: false,
      changedObjectId: "",
      changedObjectAttribute: "",
      inputType: "text",
      emailSend: false,
      errorMessageIsVisible: false,
    };
  },
  mounted() {
    axios
      .get(`https://sizablepot.backendless.app/api/data/windows`)
      .then((response) => (this.windows = response.data));
  },
  methods: {
    fetchData() {
      axios
        .get(`https://sizablepot.backendless.app/api/data/windows`)
        .then((response) => (this.windows = response.data));
    },
    addNewWindow() {
      this.$router.push("/newWindow");
      console.log(this.$store.state.client);
    },
    changeValue(id, attr, inputType) {
      this.changeValueIsVisible = true;
      this.changedObjectId = id;
      this.changedObjectAttribute = attr;
      this.inputType = inputType;
      this.newValue = "";
    },
    cancel() {
      this.changeValueIsVisible = false;
      this.changeObjectId = "";
      this.changedObjectAttribute = "";
      this.inputType = "text";
    },
    saveNewValue() {
      let id = this.changedObjectId;
      let attrToChange = this.changedObjectAttribute;
      let value = this.newValue;
      parseInt(value);
      let valueToChange;
      valueToChange = { [attrToChange]: value };

      axios
        .put(
          `https://sizablepot.backendless.app/api/data/windows/${id}`,
          valueToChange
        )
        .then((response) => {
          if (response.status === 200) {
            this.fetchData();
          }
        })
        .catch((error) => {
          this.errorMessageIsVisible = true;
          setTimeout(() => {
            this.errorMessageIsVisible = false;
          }, "7000");
          console.log(error);
        });
      this.changeValueIsVisible = false;
    },
    deleteTable(id) {
      let text = "Czy na pewno chcesz usunąć konfigurację okna?";
      if (confirm(text) == true) {
        axios
          .delete(`https://sizablepot.backendless.app/api/data/windows/${id}`)
          .then((response) => {
            console.log(response);
            console.log(`Deleted window with ID ${id}`);
            this.fetchData();
          })
          .catch((error) => {
            this.errorMessageIsVisible = true;
            setTimeout(() => {
              this.errorMessageIsVisible = false;
            }, "5000");
            console.log(error);
          });
      } else {
        return;
      }
    },
    submit() {
      this.fetchData();
      // let mail = this.yourEmail;
      var windowsToSend = [];

      //gets table
      var winTables = document.getElementsByClassName("windowTable");
      //var winTable = document.getElementById('windowTable');
      //gets rows of table
      for (let i = 0; i < winTables.length; i++) {
        var rowLength = winTables[i].rows.length;

        //loops through rows
        for (var j = 1; j < rowLength - 1; j++) {
          //gets cells of current row
          var oCells = winTables[i].rows.item(j).cells;

          var atrr = oCells.item(0).innerHTML;
          var value = oCells.item(1).innerHTML;
          var keyValue = "<br>" + atrr + ": " + value;
          windowsToSend.push(keyValue);
        }
        windowsToSend.push("<br><br>");
      }

      const templateParams = {
        Okna: windowsToSend,
        yourEmail: this.yourEmail,
      };
      emailjs
        .send(
          "service_u5bo6u3",
          "template_7qaf81v",
          templateParams,
          "Bk9OW_oJI8NKfrxhJ"
        )
        .then(
          (response) => {
            this.emailSend = true;
            console.log("SUCCESS!", response.status, response.text);
          },
          (err) => {
            this.errSend = true;
            console.log("FAILED...", err);
          }
        );

      setTimeout(() => {
        console.log("I'm here");
        this.emailSend = false;
        this.clearStore();
        //   this.$router.push('/');
      }, 5000);
    },
    clearStore() {
      //           this.$router.push('/');
      (this.$store.state.winQuantity = ""),
        (this.$store.state.windowSystem = ""),
        (this.$store.state.windowType = ""),
        (this.$store.state.winWidth = ""),
        (this.$store.state.winHeight = ""),
        (this.$store.state.winProfile = ""),
        (this.$store.state.winMilling = ""),
        (this.$store.state.winHandles = ""),
        (this.$store.state.winOpen = ""),
        (this.$store.state.winPost = ""),
        (this.$store.state.winDoorStep = ""),
        (this.$store.state.winInsideColor = ""),
        (this.$store.state.winInsideColorRAL = ""),
        (this.$store.state.winOutsideColor = ""),
        (this.$store.state.winOutsideColorRAL = ""),
        (this.$store.state.winFilling = ""),
        (this.$store.state.winGlassesNumber = ""),
        (this.$store.state.winBrake = ""),
        (this.$store.state.winDiffuserDripCap = ""),
        (this.$store.state.winHooks = "");
      this.$store.state.winForviders = "";
      this.$store.state.winMuntins = "";
      this.$store.state.winMuntinType = "";
      this.$store.state.winMuntinColor = "";
      this.$store.state.winMuntinColorRAL = "";
      this.$store.state.winCuantityHorizontalMuntins = "";
      this.$store.state.winCuantityVerticalMuntins = "";
      this.$store.state.winFitting = "";
      this.$store.state.winEspagnolette = "";
      this.$store.state.winGrabber = "";
      this.$store.state.winDescription = "";
      this.$store.state.Windows = [];
      this.yourEmail = "";
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.email-container {
  margin: 20px;
}
.email-send-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  margin: 20px;
  padding: 15px;
  background-color: #4caf50;
}
.add-new-window {
  margin: 20px 20px 0 20px;
  padding: 15px;
}
table {
  margin: 20px;
  width: 50%;
}
th {
  height: 50px;
  font-size: 20px;
}
td {
  padding: 5px;
  height: 20px;
  font-size: 18px;
}
td:nth-child(2) {
  width: 70%;
}
table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
}
button {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 3px 20px;
}
.submit {
  margin: 20px 20px 0 20px;
  height: 50px;
  background-color: #006600;
  color: white;
  font-weight: 600;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
input {
  font-size: 19px;
  height: 30px;
  padding: 5px;
  width: 100%;
}
.new-value-box {
  position: fixed;
  top: 20vh;
  left: 30%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  margin: 0;
  overflow: hidden;
}
.new-value-box div {
  margin: 15px;
  width: 100%;
}
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-message {
  display: inline-block;
  margin: 20px auto;
  color: red;
  font-size: bolder;
  font-size: 21px;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.button-container button {
  margin: 10px;
  padding: 10px 20px;
}
.delete-table-button {
  background-color: #990000;
  color: white;
}
@media only screen and (min-width: 0px) and (max-width: 1200px) {
  body {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .container {
    width: 100%;
    font-size: 17px;
    margin: 0;
    padding: 0;
  }
  table {
    margin: 20px 5px;
    width: 100%;
  }
  th {
    height: 40px;
    font-size: 18px;
  }
  td {
    padding: 5px;
    height: 20px;
    font-size: 15px;
  }
  .submit {
    margin: 5px 5px 0 5px;
    height: 50px;
    width: 100%;
  }
  .add-new-window {
    margin: 5px 5px 0 5px;
    padding: 15px;
    width: 100%;
  }
  .email-send-message {
    width: 100%;
    font-size: 17px;
    margin: 20px 0;
    padding: 10px;
    background-color: #4caf50;
  }
  .new-value-box {
    position: fixed;
    top: 20vh;
    left: 10%;
    width: 80%;
  }
  .new-value-box div {
    margin: 15px;
    width: 100%;
  }
  td:nth-child(2) {
    width: 60%;
  }
  td:nth-child(1) {
    width: 20%;
  }
  td:nth-child(3) {
    width: 20%;
  }
  input {
    font-size: 17px;
    height: 30px;
    padding: 5px;
    width: 100%;
    margin: 0;
  }
  button {
    padding: 3px 15px;
    font-size: 16px;
  }
}
</style>
